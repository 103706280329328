$blue: #3a6190 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #ea4335 !default;
$orange: #af5f29 !default;
$yellow: #f6ac33 !default;
$green: #006400 !default;
$teal: #43bbce !default;
$cyan: #43bbce !default;

@import "~bootstrap/scss/bootstrap.scss";

:root {
    --doc-height: 100vh;
}

html,
body {
    height: 100vh; /* Fallback height */
    height: var(--doc-height);
    scroll-behavior: smooth;
}

img {
    font-style: italic;
    font-size: 12px;
}

/* Responsive font sizes */
@media (max-width: 768px) {
    html {
        font-size: 80%;
    }
}

/* Adding Asterix to the end of the class name will make it a required field */
.required label > :first-child:after,
.form-group.required label > :first-child:after {
    content: " *";
    color: #ea4335;
}

.is-invalid::placeholder {
    color: #ea4335;
}

.form-control.is-invalid {
    z-index: 0 !important;
}

/* Block resize of textarea */
.textarea-resize {
    resize: none;
    z-index: 0;

    &:focus {
        z-index: 0 !important;
    }
}

/* Hover pointer for Icon */
.hover-icon {
    cursor: pointer;
}

.dropdown-menu {
    transform: inherit !important;
    inset: inherit !important;
}

.table-responsive {
    overflow-x: unset !important;
    overflow-y: inherit !important;
}


html, body {
    overscroll-behavior: none;
}